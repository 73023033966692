figure img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  object-position: center;
  /* border-radius: 0.5rem; */
  /* transition: all 0.3s ease-in-out; */
}
.credit-anc {
  text-align: center;
  margin-top:35px ;
}
.credit-anc a{
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  background-color: #3138cb ;
  padding: 0.5rem;
  border-radius: 30px;
}
.credit-anc a:hover{
  color: #3138cb ;
  background-color: #fff;
  
}
